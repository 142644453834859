// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Menu } from 'src/app/utility';
import {LcmsEnvironment} from "../../app/utility/models/lcms-environment.interface";


const SUPERADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.USERGROUPS,
  Menu.LESSONS,
  Menu.GLOSSARY,
  Menu.NEWS,
  Menu.PARTNER,
  Menu.STATS,
  Menu.SYSTEMTEXT,
  Menu.STATICCONTENT,
  Menu.PAGE_NEXT,
  Menu.LESSONPATHS,
  Menu.ASSIGNMENT,
  Menu.HELP,
  Menu.BLENDEDLEARNING_LESSONPATH
];

export const environment: LcmsEnvironment = {
  production: false,
  e2e: false,
  statisticsKey: '',

  server: {
    api     : 'https://api-lcms.staging.e5-suite.de/backend',
    apiNext : 'https://staging.lcms-next.dev.nettrek.group',
    frontend: 'https://brainfood-academy.staging-lcms.e5-suite.de',
    tenant  : 'breed'
  },

  roles: [
    SUPERADMIN,
    [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      18
    ],
    [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8
    ]
  ],
  difficulties : [
    {
      value : 1,
      label: 'Leicht'
    },
    {
      value : 2,
      label: 'Ausgewogen'
    },
    {
      value : 3,
      label: 'Schwer'
    }
  ],

  classifications : [
    {
      value : 'demo',
      label : 'Demo'
    },
    {
      value : 'basic',
      label: 'Basic',
    },
    {
      value : 'pro',
      label : 'Pro'
    }
  ],

  tenantConfig: {
    lesson: {
      plannedLesson: true,
    },
    lessonPath: {
      duplicate: true,
    },
    user: {
      exportUserList: true,
      demoUser: true
    },
    certificate: {
      allowDeactivation: true,
      forLessonPaths: true,
      demo_download: true
    }
  },
  trackingUrl: 'https://api-lcms.staging.e5-suite.de/frontend/eagle5trackings/',
  lessonLink: ['https://api-lcms.staging.e5-suite.de/wbts/breed/lesson_', '/eagle5_scorm_adapter.html?startpage='],
  createDefaults: {
    lessonPaths: {
      isprivate: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';
